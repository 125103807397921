.strokeMarketplaceColor {
  stroke: var(--marketplaceColor);
}

.fillSuccessColor {
  fill: var(--colorSuccess);
}

.strokeLightColor {
  stroke: var(--colorBlack1);
}
